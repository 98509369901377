
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.s-search-type {
  &__radio-group, &__childs, &__child {
    margin-bottom: $base-gutter / 1.5;
  }
  &__child {
    display: flex;
    align-items: center;
    gap: $base-gutter;
  }
}
