
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.custom-employee-search-list {
  .esmp-table col {
    width: auto!important;
  }
  &__table {
    margin: 10px 0 0 0;
  }
  &__action {
    cursor: pointer;
    color: $color-grayscale-60;
    &:hover {
      color: $color-black;
    }
  }
  &__select-option {
    &-fio {
      margin-right: 16px;
      font-size: 14px;
      line-height: 20px;
      color: $color-black;
      transition: color .15s;
    }
    &-email {
      font-size: 12px;
      line-height: 16px;
      color: $color-black-op-50;
      margin-right: 16px;
    }
    &:hover &-fio {
      color: $color-primary-1-night;
    }
  }
}
